import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CircularProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import { useAuth } from "layouts/authentication/AuthContext";
import axios from "axios";
import BASE_URL from "apiConfig";

// Dynamic Sidenav logo fetching
const api = axios.create({
  baseURL:
   BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const DashboardNavbar = React.lazy(() => import("examples/Navbars/DashboardNavbar"));

// Lazy load components
const Sidenav = React.lazy(() => import("examples/Sidenav"));
const LeadDetails = React.lazy(() => import("layouts/tables/Details"));
const ClientDetails = React.lazy(() => import("layouts/tablesclient/Details"));
const SignIn = React.lazy(() => import("layouts/authentication/sign-in/index"));
const Dashboard = React.lazy(() => import("layouts/dashboard"));
const RmDetails = React.lazy(() => import("layouts/rm/RmDetails"));
const Status = React.lazy(() => import("layouts/settings"));
const Reports = React.lazy(() => import("layouts/reports"));
const ProtectedRoute = React.lazy(() =>
  import("layouts/authentication/ProtectedRoute")
);
const Tables3 = lazy(() => import("layouts/rm"));
const ForgotPassword = React.lazy(() =>
  import("layouts/authentication/forgot-password/index")
);
const ResetPassword = React.lazy(() =>
  import("layouts/authentication/reset-password/index")
);

export default function App() {
  const isMobile = useMediaQuery("(max-width:1200px)");
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const [logo, setLogo] = useState(null); // State to store logo URL
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth(); // Use Auth Context

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Fetch logo from backend
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await api.get("/settings");
        setLogo(response.data.logo_url);
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };
    fetchLogo();
  }, []);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const allRoutes = routes(); // Call the routes function to get the routes array

  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      }
    >
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={logo} // Pass the dynamic logo URL here
              routes={allRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {isMobile && isAuthenticated && <DashboardNavbar />}
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {isAuthenticated ? (
            <>
              {getRoutes(allRoutes)}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/leads/:id" element={<LeadDetails />} />
              <Route path="/clients/:id" element={<ClientDetails />} />
              <Route path="/rms/:rmId" element={<RmDetails />} />
              <Route path="/settings" element={<Status />} />
              <Route path="/reports" element={<Reports />} />
              <Route
                path="/RMData"
                element={
                  <ProtectedRoute allowedRoles={["Admin"]}>
                    <Tables3 />
                  </ProtectedRoute>
                }
              />
            </>
          ) : (
            <Route path="*" element={<SignIn />} />
          )}
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </Suspense>
  );
}
